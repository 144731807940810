<template>
    <main class='mt-0 main-content main-content-bg'>
        <section>
            <div class="page-header min-vh-75">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                            <div class="mt-8 card card-plain">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-danger text-gradient capitalize">Permission Denied</h3>
                                    <p class="font-weight-bold text-justify mb-0">Oops! It seems you don't have the necessary permissions to access this page. If you believe this is an error or if you need assistance, please contact our support team at <strong><a href="mailto:info@venuelista.com">EMAIL</a></strong>. We're here to help you get back on track and ensure you have the access you need. Thank you for your understanding.</p>
                                </div>
                                <div class="card-body">
                                    <form role="form" method="post" class="text-start" @submit.prevent="submit">
										<div class="text-center">
											<soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="danger"
                                                full-width
                                                :disabled="loading"
                                                :loading="loading"
                                            >
                                                Back To Home
                                            </soft-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{ backgroundImage: `url(${require('@/assets/img/venue-management-software.jpg')})` }"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import SoftButton from '@/components/SoftButton'

const body = document.getElementsByTagName('body')[0]

export default {
    name: 'Permission',

    data() {
        return {
            loading: false,
        }
    },

    components: {
        SoftButton,
    },

    created() {
        body.classList.remove('bg-gray-100')
    },

    methods: {

        ...mapActions('auth', [
            'logout',
        ]),

        submit() {
            this.loading = true

            this.logout().then(() => {
                this.$router.push({
                    name: 'Dashboard'
                })
            }).catch(({ response }) => {
                this.message = response?.data?.message || 'Something went wrong'
            }).finally(() => {
                this.loading = false
            })
        }
    },

    beforeUnmount() {
        body.classList.add('bg-gray-100')
    },
}
</script>
